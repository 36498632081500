import React from 'react'
import { Moment } from 'moment'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { DMY_CASUAL_SHORT_FORMAT } from 'constants/dateFormats'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import StructureLabel from 'components/Luxkit/Label/StructureLabel'
import AspectRatio from 'components/utils/AspectRatio'
import styled from 'styled-components'
import { rem } from 'polished'
import TextButton from 'components/Luxkit/Button/TextButton'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Image from 'components/Common/Image/Image'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'

// WELL THIS KINDA SUCKS AND MAKES NO SENSE
// WHY ARE THEY NOT ALL TAGS?
const TAG_PATHS: Record<string, string> = {
  Accommodation: '/tag/accommodation',
  'Arts & Culture': 'tag/arts-culture',
  'Bucket List': '/homepage/bucket-list',
  Destinations: '/destinations',
  Honeymoon: '/homepage/honeymoon',
  Islands: '/tag/islands',
  Experiences: '/tag/experiences',
  TV: '/tag/tv',
  'Family Friendly': 'tag/family-friendly',
  'Food & Wine': '/tag/food-wine',
  'Outdoor & Adventure': '/tag/outdoor-adventure',
  'Sun & Beach': '/tag/sun-beach',
  Romantic: '/tag/romantic',
  Spa: '/tag/spa',
  Europe: '/destinations/europe',
  Asia: '/asia',
  Explore: '/category/inspiration',
  Italy: '/category/destinations/europe/italy',
  Dubai: '/destinations/dubai/',
  'Middle East': '/middle-east/',
  Africa: '/africa/',
  Greece: '/category/greece',
  'Ultra Lux': '/tag/ulta-lux',
  UK: '/category/destinations/uk',
  Bali: '/destinations/indonesia/bali/',
  Indonesia: '/destinations/indonesia/',
  France: '/destinations/europe/france/',
  Spain: '/destinations/europe/spain',
  Maldives: '/destinations/maldives/',
  'Where to Stay': '/destinations/maldives/where-to-stay-maldives/',
  'What to see and do': '/destinations/europe/spain/what-to-see-and-do-spain',
  Phuket: '/destinations/thailand/phuket/',
  Thailand: '/destinations/thailand/',
  'New York': '/destinations/usa/new-york/',
  USA: '/destinations/usa/',
  Fiji: '/fiji',
  Australia: '/destinations/australia/',
  Queensland: '/destinations/australia/queensland/',
  'New South Wales': '/destinations/australia/new-south-wales/',
  'Where to eat and drink': '/destinations/thailand/where-to-eat-and-drink-thailand/',
  London: '/destinations/uk/london/',
  'signature-series': '/inspiration/?s=signature+series',
}

const Root = styled(Group)`
  width: 75vw;
  position: relative;

  ${mediaQueryUp.tablet} {
    width: 40vw;
  }

  ${mediaQueryUp.desktop} {
    width: unset;
  }
`

const TagLabel = styled(ClickableLink)`
  z-index: 1;
`

const TagLabelGroup = styled(LabelGroup)`
  height: ${rem(24)};
  overflow: hidden;
`

const NestedLink = styled(ClickableLink)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;

`

interface Props {
  imageId: string;
  title: string;
  date: Moment;
  description: string;
  tags: Array<string>;
  slug: string;
  lineClamp?: number;
}

function BlogCard(props: Props) {
  const { lineClamp = 3, title, date, description, tags, slug, imageId } = props

  return (
    <Root direction="vertical" verticalAlign="space-between" gap={12}>
      <VerticalSpacer gap={12} >
        <AspectRatio ratio="16:9">
          <Image alt={title} aspectRatio="16:9" id={imageId} fit="center" dpr={2} width="375"/>
        </AspectRatio>
        <VerticalSpacer gap={4}>
          <Heading variant="heading4" lineClamp={2}>{title}</Heading>
          <BodyText variant="medium">{date.format(DMY_CASUAL_SHORT_FORMAT)}</BodyText>
        </VerticalSpacer>
        <TagLabelGroup wrap="wrap">
          {tags.map(tag => (
            <TagLabel key={tag} target="_blank" href={`https://luxuryescapes.com/inspiration${TAG_PATHS[tag]}`}>
              <StructureLabel variant="standard">
                {tag}
              </StructureLabel>
            </TagLabel>
          ))}
        </TagLabelGroup>
        <BodyText variant="medium" lineClamp={lineClamp}>{description}</BodyText>
      </VerticalSpacer>
      <CSSBreakpoint min="tablet">
        <Group direction="horizontal" horizontalAlign="end">
          <TextButton kind="primary">
            View article
          </TextButton>
        </Group>
      </CSSBreakpoint>
      <NestedLink target="_blank" href={`https://luxuryescapes.com/inspiration/${slug}`} />
    </Root>
  )
}

export default React.memo(BlogCard)
