import React, { useMemo } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import Image from 'components/Common/Image/Image'
import switchFunc from 'lib/function/switchFunc'
import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import LandmarkIcon from 'components/Luxkit/Icons/custom/LandmarkIcon'
import LineBedIcon from 'components/Luxkit/Icons/line/LineBedIcon'
import LineCompassIcon from 'components/Luxkit/Icons/line/LineCompassIcon'
import LinePlaneFlyIcon from 'components/Luxkit/Icons/line/LinePlaneFlyIcon'
import LineGlobeAltIcon from 'components/Luxkit/Icons/line/LineGlobeAltIcon'
import { getDestinationImage, isDestinationSearchItem } from 'lib/search/searchUtils'

const ImageBlock = styled.div`
  flex-shrink: 0;
  width: ${rem(68)};
  height: ${rem(92)};
`
const IconBlock = styled.div`
  display: flex;
  width: ${rem(68)};
  height: ${rem(92)};
  padding: ${rem(30)} ${rem(18)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: ${props => props.theme.palette.neutral.default.three};
  border: ${rem(1)} solid ${props => props.theme.palette.neutral.default.five};
`

const searchItemPropertiesSwitch = switchFunc({
  city: { Icon: LineMapMarkerIcon, typeIndicator: 'City' },
  state: { Icon: LineMapMarkerIcon, typeIndicator: 'State' },
  province: { Icon: LineMapMarkerIcon, typeIndicator: 'Province' },
  landmark: { Icon: LandmarkIcon, typeIndicator: 'Landmark' },
  property: { Icon: LineBedIcon, typeIndicator: 'Hotel' },
  experience: { Icon: LineCompassIcon, typeIndicator: null },
  destination: { Icon: LineMapMarkerIcon, typeIndicator: null },
  airport: { Icon: LinePlaneFlyIcon, typeIndicator: null },
  anywhere: { Icon: LineGlobeAltIcon, typeIndicator: 'Anywhere' },
}, { Icon: LineMapMarkerIcon, typeIndicator: null })

interface Props {
  imageId: string | undefined;
  searchItem: App.SearchItem;
}

function RecentSearchImage(props: Props) {
  const { imageId, searchItem } = props

  const displayImageId = useMemo(() => {
    if (imageId) {
      return imageId
    }

    if (isDestinationSearchItem(searchItem)) {
      return getDestinationImage(searchItem.value)
    }

    return undefined
  }, [imageId, searchItem])

  const fallbackIcon = useMemo(() => {
    let iconToSelect = searchItem.searchType === 'destination' ? searchItem.destinationType : searchItem.searchType

    if (searchItem.format.mainText === 'Anywhere') {
      iconToSelect = 'anywhere'
    }

    const { Icon } = searchItemPropertiesSwitch(iconToSelect ?? 'destination')

    return <Icon style={{ width: 32, height: 32 }}/>
  }, [searchItem])

  return (
    <ImageBlock>
      {displayImageId && <Image
          fit="center"
          width={68}
          height={92}
          dpr={2}
          id={displayImageId}
      />}
      {!displayImageId && <IconBlock>
        {fallbackIcon}
      </IconBlock>}
    </ImageBlock>
  )
}

export default RecentSearchImage
