import React, { useCallback } from 'react'
import styled from 'styled-components'
import Group from 'components/utils/Group'
import Image from 'components/Common/Image'
import * as Analytics from 'analytics/analytics'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { promotionClickEvent } from 'analytics/snowplow/events'
import ClickableLink from 'components/Common/Clickable/ClickableLink'

const Banner = styled(Group)`
  width: 75vw;

  ${mediaQueryUp.tablet} {
    width: 40vw;
  }

  ${mediaQueryUp.desktop} {
    width: unset;
  }
`

interface Props {
  banner: App.EuropeInspirationBanner;
  onLoad: (banner: App.EuropeInspirationBanner) => void;
}

function EuropeInspirationBanner(props: Props) {
  const { banner, onLoad } = props

  const onBannerClick = useCallback(() => {
    Analytics.trackEvent(promotionClickEvent(banner.name))
  }, [banner])

  const onBannerLoad = useCallback(() => {
    onLoad(banner)
  }, [banner, onLoad])

  return (
    <Banner direction="vertical">
      <ClickableLink key={banner.imageId} to={banner.to} onClick={onBannerClick} target="_blank">
        <Image
          fit="center"
          loading="lazy"
          quality="good"
          id={banner.imageId}
          onLoad={onBannerLoad}
        />
      </ClickableLink>
    </Banner>
  )
}

export default EuropeInspirationBanner
