import FilterChip from 'components/Luxkit/Chips/FilterChip'
import { rem } from 'polished'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import HorizontalContainerWithFadedOverflow from 'components/Common/HorizontalContainerWithFadedOverflow'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { getHighIntentHotelOffers } from 'selectors/recomendations/highIntentSelectors'
import { connect } from 'react-redux'
import HighIntentOffersCarousel from 'components/OfferList/HighIntent/HighIntentOffersCarousel'
import { deleteRecentSearch, getRecentSearches } from 'components/Search/utils'
import RecentSearchesCarousel from 'home/components/RecentSearches/RecentSearchesCarousel'
import { without } from 'lib/array/arrayUtils'
import Heading from 'components/Luxkit/Typography/Heading'
import * as Analytics from 'analytics/analytics'

const SectionContainer = styled(VerticalSpacer)`
  padding-top: ${rem(32)};
  padding-bottom: ${rem(24)};
`

type RecentSearchesTab =
  | 'recentSearches'
  | 'previouslyViewedOffers'

interface RecentSearchesTabOptions {
  id: RecentSearchesTab;
  title: string;
}

interface Props {
  previouslyViewedOffers: Array<App.HighIntentOffer>
  memberId: string | undefined
}

function RecentSearches(props: Props) {
  const { previouslyViewedOffers, memberId } = props

  const [
    selectedTab,
    setSelectedTab,
  ] = useState<RecentSearchesTab | undefined>()
  const initialRecentSearches = getRecentSearches(memberId)
  const [
    recentSearches,
    setRecentSearches,
  ] = useState<Array<App.FullRecentSearch>>(initialRecentSearches)

  useEffect(() => {
    const allRecentSearches = getRecentSearches(memberId)
    setRecentSearches(allRecentSearches)
  }, [memberId])

  const onCloseRecentSearch = useCallback((recentSearch: App.FullRecentSearch) => {
    deleteRecentSearch(recentSearch.recentSearchId, memberId!)
    setRecentSearches(without(recentSearches, recentSearch))
  }, [recentSearches, memberId])

  function onTabClick(option: RecentSearchesTabOptions) {
    setSelectedTab(option.id)
    trackTabClick(option)
  }

  function trackTabClick(option: RecentSearchesTabOptions) {
    if (option.id === 'recentSearches') {
      Analytics.trackClientEvent({
        subject: 'recent-search-tab',
        action: 'clicked',
        category: 'logging',
        type: 'interaction',
        optimizelyEventId: '30073941019',
        optimizelyEventKey: 'recent_search_tab_click',
      })
    } else {
      Analytics.trackClientEvent({
        subject: 'previously-viewed-search-tab',
        action: 'clicked',
        category: 'logging',
        type: 'interaction',
        optimizelyEventId: '30045850989',
        optimizelyEventKey: 'previously_viewed_tab_click',
      })
    }
  }

  function onPreviouslyViewedOfferClick() {
    Analytics.trackClientEvent({
      subject: 'previously-viewed-search-tile',
      action: 'clicked',
      category: 'logging',
      type: 'interaction',
      optimizelyEventId: '30101960325',
      optimizelyEventKey: 'previously_viewed_click',
    })
  }

  const RecentSearchesTabOptions: Array<RecentSearchesTabOptions> = useMemo(() => {
    const options: Array<RecentSearchesTabOptions> = []

    if (recentSearches.length) {
      options.push({ id: 'recentSearches', title: 'Recent Searches' })
    }
    if (previouslyViewedOffers.length) {
      options.push({ id: 'previouslyViewedOffers', title: 'Previously Viewed Offers' })
    }

    const hasRecentSearches = recentSearches.length > 0
    const hasPreviouslyViewedOffers = previouslyViewedOffers.length > 0

    if (!selectedTab || (hasRecentSearches && !hasPreviouslyViewedOffers)) {
      setSelectedTab('recentSearches')
    } else if (!selectedTab && hasPreviouslyViewedOffers || (!hasRecentSearches && hasPreviouslyViewedOffers)) {
      setSelectedTab('previouslyViewedOffers')
    }

    return options
  }, [recentSearches.length, previouslyViewedOffers.length, selectedTab])

  if (!RecentSearchesTabOptions.length) {
    return null
  }

  return (
    <SectionContainer gap={16}>
      <LayoutContainer>
        <VerticalSpacer gap={16}>
          <Heading variant="heading6">
            Continue searching for your next escape
          </Heading>
          <HorizontalContainerWithFadedOverflow>
            <Group direction="horizontal" gap={8}>
              {RecentSearchesTabOptions.map((option) => <FilterChip
                selected={option.id === selectedTab}
                onClick={() => onTabClick(option)}
                key={option.id}
                size="medium"
              >
                {option.title}
              </FilterChip>)}
            </Group>
          </HorizontalContainerWithFadedOverflow>
        </VerticalSpacer>
      </LayoutContainer>
      <LayoutContainer>
        {selectedTab === 'recentSearches' && <RecentSearchesCarousel
          recentSearches={recentSearches}
          onClose={onCloseRecentSearch}
        />}
        {selectedTab === 'previouslyViewedOffers' && <HighIntentOffersCarousel
          highIntentOffers={previouslyViewedOffers}
          analyticsLabel="hotels"
          onClick={onPreviouslyViewedOfferClick}
        />}
      </ LayoutContainer>
    </SectionContainer>
  )
}

function mapStateToProps(state: App.State) {
  return {
    previouslyViewedOffers: getHighIntentHotelOffers(state),
    memberId: state.auth.account.memberId,
  }
}

export default connect(mapStateToProps)(RecentSearches)
