import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import React, { useContext } from 'react'
import BlogCard from './BlogCard'
import TextButton from 'components/Luxkit/Button/TextButton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import { DESTINATION_LANDING_PAGE_ARTICLES, HOMEPAGE_ARTICLES } from './constants'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import { rem } from 'polished'
import styled from 'styled-components'
import { capitalise } from 'lib/string/stringUtils'
import { useParams } from 'react-router'
import { SupportedDestinations } from 'home/constants'
import GeoContext from 'contexts/geoContext'

const BlogCarouselContainer = styled(LayoutContainer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

function BlogCarousel() {
  const params = useParams<{ destination?: SupportedDestinations}>()
  const { destination } = params
  const destinationName = destination === SupportedDestinations.GREECE ? 'Greek' : destination
  const { currentRegionCode } = useContext(GeoContext)
  const articles: Array<App.Article> | undefined = destination ? DESTINATION_LANDING_PAGE_ARTICLES[destination] : HOMEPAGE_ARTICLES[currentRegionCode]

  if (!articles) {
    return null
  }

  return (<BlogCarouselContainer>
    <Group direction="vertical" gap={8}>
      <Group
        direction="horizontal"
        horizontalAlign="space-between"
        gap={16}
      >
        {!destination && <Heading variant="heading2">
          Be <i>inspired</i> for your next adventure
        </Heading>}
        {destination && <Heading variant="heading2">
          Be inspired for your next <i>{capitalise(destinationName)}</i> adventure
        </Heading>}
        <TextButton kind="tertiary" size="medium" href="https://luxuryescapes.com/inspiration/" target="_blank">
          View all
        </TextButton>
      </Group>
      <BodyText variant="large">
        Dream with our collection of articles and local travel trips
      </BodyText>
      <CardCarousel pageSize={3}>
        {articles.map(article => (
          <BlogCard
            key={article.slug}
            imageId={article.imageId}
            title={article.title}
            date={article.date}
            description={article.description}
            tags={article.tags}
            slug={article.slug}
          />
        ))}
      </CardCarousel>
    </Group>
  </BlogCarouselContainer>
  )
}

export default React.memo(BlogCarousel)
