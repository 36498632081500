import React, { useMemo } from 'react'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_HOTEL } from 'constants/offer'
import OfferCardCarouselSection from 'components/Common/OfferCardCarousel/OfferCardCarouselSection'
import Places from 'constants/places'
import { useAppSelector } from 'hooks/reduxHooks'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { LUXURY_PLUS } from 'luxPlus/constants/base'

function LuxPlusOfferCarousel() {
  const canViewLuxPlusBenefits = useAppSelector(state => checkCanViewLuxPlusBenefits(state))

  const filters: App.OfferListFilters = useMemo(() => ({
    offerTypes: [OFFER_TYPE_HOTEL, OFFER_TYPE_ALWAYS_ON],
    luxPlusFeatures: ['Member exclusive'],
    destinations: [Places.Anywhere.id],
    sortBy: 'luxPlusList',
    scheduleVisibilities: ['luxPlusExclusiveVisible'],
  }), [])

  return (
    <OfferCardCarouselSection
      tileStyle={canViewLuxPlusBenefits ? 'card' : 'locked-card'}
      title={`Hidden offers, just for ${LUXURY_PLUS.PROGRAM_NAME} members`}
      subtitle={`Unlock hidden hotel offers available exclusively for ${LUXURY_PLUS.PROGRAM_NAME} members. These escapes aren't available anywhere else`}
      filters={filters}
      id="exclusive_offers"
      hideWhenEmpty
    >
    </OfferCardCarouselSection>
  )
}

export default React.memo(LuxPlusOfferCarousel)
