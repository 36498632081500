import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { fetchPeopleLikeMeRecommendations, fetchPersonalisedAlternativeOffers, fetchPersonalisedAlternativeOffersWithOfferEnquires, fetchPersonalisedPreferenceOffers } from 'actions/RecommendationActions'

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import Heading from 'components/Luxkit/Typography/Heading'
import { take, unique, without } from 'lib/array/arrayUtils'
import { rem } from 'polished'
import styled from 'styled-components'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LayoutContainer from 'components/Common/LayoutContainer'
import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { mediaQueryUp } from 'components/utils/breakpoint'
import OfferCarouselHeader from 'home/components/OfferCarousels/common/OfferCarouselHeader'
import OfferListCarousel from 'components/OfferList/OfferListCarousel'
import TextLink from 'components/Luxkit/TextLink'
import { MAX_CAROUSEL_ITEM_COUNT } from './common/constants'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { LIST_SOURCE_LERE_UNKNOWN_MODEL, getLereListSource } from 'components/Recommendations/common/constant'
import OfferListEventsContext, {
  OfferListEventHandler,
  OfferListEventsDispatchAction,
  OfferListEventsProvider,
} from 'components/OfferList/OfferListEventsContext'
import GeoContext from 'contexts/geoContext'
import { getAlternativeOfferEnquiry, getAlternativeOfferKey, personalisedAlternativesOfferType } from 'selectors/recomendations/personalisedAlternatives'
import HotelOfferCarousel from 'home/components/OfferCarousels/HotelOfferCarousel'
import { get as getLocalStorage } from 'lib/storage/expirableLocalStorage'
import { getHighIntentOffers } from 'selectors/recomendations/highIntentSelectors'

const SectionContainer = styled(VerticalSpacer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

const Container = styled(Group)`
  background-color: ${props => props.theme.palette.neutral.default.seven};
  padding: ${rem(20)};
  width: ${rem(320)};

  ${mediaQueryUp.tablet} {
    padding: ${rem(40)};
  }

  ${mediaQueryUp.largeDesktop} {
    padding: ${rem(60)};
  }

`

interface Props {
  userGivenName?: string;
  isLoggedIn: boolean;
  highIntentOffers: App.RecommendationState['highIntent']['offers'];
  highIntentState: App.RecommendationState['highIntent']['state'];
  alternativesByUser: App.RecommendationState['personalisedAlternatives'];
  alternativesByOffers: App.RecommendationState['personalisedAlternativesWithOfferEnquiries'];
  peopleLikeMe: App.RecommendationState['personalisedPeopleLikeMe'];
  onHotelListEvent: (dispatchAction: OfferListEventsDispatchAction) => void;
  preferencesByUser: App.RecommendationState['personalisedPreferences'];
}

/**
 * Top pick for me carousel
 *
 * The offers are selected based on (fallback to next if no recos)
 * 1. personalised alternative
 * 2. people like me
 */
function TopPicksForMeCarousel({
  userGivenName,
  isLoggedIn,
  highIntentOffers,
  highIntentState,
  alternativesByUser,
  alternativesByOffers,
  peopleLikeMe,
  onHotelListEvent,
  preferencesByUser,
}: Props) {
  const dispatch = useAppDispatch()
  const { currentRegionCode } = useContext(GeoContext)
  const travelPreferencesUpdatedInLast24Hours = useMemo(() => getLocalStorage<number>('travelPreferencesLastUpdatedAt'), [])
  const domainUserId = useAppSelector(state => state.auth.domainUserId)
  const alternativeSupportedHighIntent = useMemo(() => {
    // Filter out offers with categories which are not support for LERE
    return highIntentOffers.filter(offer => personalisedAlternativesOfferType.includes(offer.offerType))
  }, [highIntentOffers])

  const highIntentOfferIds: Array<string> = useMemo(
    () => highIntentOffers.map(offer => offer.offerId),
    [highIntentOffers],
  )

  // reco from local storage
  const defaultRecommendation = useAppSelector(state => state.recommendations.defaultRecommendation)
  const shouldUseDefaultRecommendation = (
    alternativeSupportedHighIntent.length === 1 &&
    alternativeSupportedHighIntent[0].offerId === defaultRecommendation.source &&
    defaultRecommendation.offerIds.length > 5
  )

  useEffect(() => {
    if (!shouldUseDefaultRecommendation && isLoggedIn) {
      dispatch(fetchPeopleLikeMeRecommendations())
    }
  }, [dispatch, isLoggedIn, shouldUseDefaultRecommendation])

  useEffect(() => {
    if (shouldUseDefaultRecommendation) { return }

    if (isLoggedIn) {
      dispatch(fetchPersonalisedAlternativeOffers(currentRegionCode, domainUserId))
      dispatch(fetchPersonalisedPreferenceOffers())
    } else {
      dispatch(fetchPersonalisedAlternativeOffersWithOfferEnquires(currentRegionCode, domainUserId))
    }
  }, [dispatch, currentRegionCode, isLoggedIn, alternativeSupportedHighIntent, shouldUseDefaultRecommendation, domainUserId])

  const offerEnquiry = useAppSelector(getAlternativeOfferEnquiry)
  const alternativeByOffersKey = useAppSelector(getAlternativeOfferKey)

  const [recommendationsLoading, canUseAlternative] = useMemo(() => {
    if (shouldUseDefaultRecommendation) { return [false, false] }

    let recommendationsLoading: boolean = true
    let canUseAlternative: boolean = false

    if (isLoggedIn) {
      const alternativeLoading = alternativesByUser.state === 'loading'
      const plmLoading = peopleLikeMe.state === 'loading'
      canUseAlternative = !alternativeLoading && alternativesByUser.offers.length > 0
      recommendationsLoading = alternativeLoading || (!canUseAlternative && plmLoading)
    } else {
      const highIntentDone = highIntentState === 'done'
      // if they don't have local storage high intent offers, set the recommendationsLoading to false in order to fall back to hotel offers
      recommendationsLoading = !(highIntentDone && offerEnquiry.length === 0)
      // if they have local storage high intent offers, waiting for the alternative recommendations
      const alternative = alternativesByOffers[alternativeByOffersKey]
      if (highIntentDone && offerEnquiry.length > 0 && alternative) {
        recommendationsLoading = alternative.state === 'loading'
        canUseAlternative = !recommendationsLoading && alternative.offers.length > 0
      }
    }

    return [recommendationsLoading, canUseAlternative]
  }, [shouldUseDefaultRecommendation, isLoggedIn, alternativesByUser.state, alternativesByUser.offers.length, peopleLikeMe.state, highIntentState, offerEnquiry.length, alternativesByOffers, alternativeByOffersKey])

  const [recommendedOfferIds, lereModelVersion] = useMemo(() => {
    if (shouldUseDefaultRecommendation) { return [defaultRecommendation.offerIds, 'local-graph'] }

    let candidates: Array<string> = []
    let lereModelVersion: string = LIST_SOURCE_LERE_UNKNOWN_MODEL

    if (!recommendationsLoading) {
      if (isLoggedIn) {
        // if login, use alt (by user id) first, then follow by the plm as fallback
        if (canUseAlternative) {
          candidates = alternativesByUser.offers.map(offer => offer.offerId)
          lereModelVersion = alternativesByUser.lereVersion ?? lereModelVersion
        }
        else {
          candidates = peopleLikeMe.offers.map(offer => offer.offerId)
          lereModelVersion = peopleLikeMe.lereVersion ?? lereModelVersion
        }
      } else {
        // if not login, use alt (by offer enquiries)
        if (canUseAlternative) {
          candidates = alternativesByOffers[alternativeByOffersKey].offers.map(offer => offer.offerId)
          lereModelVersion = alternativesByOffers[alternativeByOffersKey].lereVersion ?? lereModelVersion
        }
      }
    }

    const preferenceOffers = preferencesByUser.offers.map(offer => offer.offerId)
    candidates = travelPreferencesUpdatedInLast24Hours ? [...preferenceOffers, ...candidates] : [...candidates, ...preferenceOffers]

    candidates = unique(candidates)
    const dedupCandidates = without(candidates, ...highIntentOfferIds)
    return [take(dedupCandidates, MAX_CAROUSEL_ITEM_COUNT), lereModelVersion]
  }, [shouldUseDefaultRecommendation, recommendationsLoading, highIntentOfferIds, defaultRecommendation.offerIds, isLoggedIn, canUseAlternative, alternativesByUser, peopleLikeMe, alternativesByOffers, alternativeByOffersKey, preferencesByUser, travelPreferencesUpdatedInLast24Hours])

  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  // this function is to override the homepage in order to pass the lere model version
  const onTPFMListEventBasedOnSource = useCallback<OfferListEventHandler>((dispatchAction) => {
    dispatchOfferListEvent(dispatchAction, { listSource: getLereListSource(lereModelVersion) })
  }, [lereModelVersion, dispatchOfferListEvent])

  const offerList = useMemo((): App.OfferList => ({
    fetching: recommendationsLoading,
    error: undefined,
    offerIds: recommendedOfferIds,
    key: 'topPicks',
  }), [recommendationsLoading, recommendedOfferIds])

  const shouldShowHotel = !isLoggedIn && !recommendationsLoading && recommendedOfferIds.length === 0

  if (isLoggedIn && !recommendationsLoading && recommendedOfferIds.length === 0) {
    return null
  }

  return (<>
    {shouldShowHotel &&
      <OfferListEventsProvider onListEvent={onHotelListEvent}>
        <HotelOfferCarousel />
      </OfferListEventsProvider>
    }
    {!shouldShowHotel &&
      <OfferListEventsProvider onListEvent={onTPFMListEventBasedOnSource}>
        <SectionContainer gap={20}>
          <LayoutContainer>
            <OfferCarouselHeader allTo={userGivenName ? '/top-picks-for-me' : ''}>
              Top picks for <i>{userGivenName || 'you'}</i>
            </OfferCarouselHeader>
            {isLoggedIn && <TextLink to="/account/travel-preferences" size="medium" weight="regular">
              Edit your preferences
            </TextLink>}
          </LayoutContainer>
          <OfferListCarousel
            overrideOfferList={offerList}
            limit={8}
            tabletLimit="default"
            tileStyle="card-medium"
          >
            {!!userGivenName && <Container direction="vertical" horizontalAlign="center" verticalAlign="center" gap={24}>
              <VerticalSpacer gap={8}>
                <Heading align="center" variant="heading5">Looking for something else?</Heading>
                <BodyText align="center" variant="medium">Discover amazing curated offers tailored to you.</BodyText>
              </VerticalSpacer>
              <TextButton kind="primary" size="medium" shape="square" to="/top-picks-for-me">
                View all
              </TextButton>
            </Container>}
          </OfferListCarousel>
        </SectionContainer>
      </OfferListEventsProvider>
    }
  </>
  )
}

function mapState(state: App.State) {
  return {
    userGivenName: state.auth.account.givenName,
    isLoggedIn: selectLoggedIn(state),
    highIntentOffers: getHighIntentOffers(state),
    highIntentState: state.recommendations.highIntent.state,
    alternativesByUser: state.recommendations.personalisedAlternatives,
    alternativesByOffers: state.recommendations.personalisedAlternativesWithOfferEnquiries,
    peopleLikeMe: state.recommendations.personalisedPeopleLikeMe,
    preferencesByUser: state.recommendations.personalisedPreferences,
  }
}

export default connect(mapState)(TopPicksForMeCarousel)
