import React, { useCallback, useMemo } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import Group from 'components/utils/Group'
import CloseButton from 'components/Common/CloseButton'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { getRecentSearchDescription } from 'components/Search/utils'
import { DEFAULT_FLEXIBLE_DURATION_RANGE } from 'constants/search'
import { formatOccupantsAsGuests } from 'lib/offer/occupancyUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import RecentSearchImage from './RecentSearchImage'
import BodyText from 'components/Luxkit/Typography/BodyText'
import NestableClickable from 'components/Common/NestableClickable'

const ContentGroup = styled(Group)`
  flex-grow: 1;
`
const TextContainer = styled(Group)`
    padding: ${rem(8)} ${rem(16)};
    display: flex;
`

const Root = styled(NestableClickable)`
  align-self: start;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  overflow: hidden;
  transition: outline 0.2s, background-color 0.2s;

  width: 75vw;

  // on tablet we match the offer card size
  ${mediaQueryUp.tablet} {
    width: ${rem(320)};
  }

  ${mediaQueryUp.desktop} {
    padding: ${rem(8)};
    width: unset;
    min-width: ${rem(320)};

    // on desktop, close button is hidden unless hover
    .close-button {
      transition: opacity 0.2s;
      opacity: 0;
      pointer-events: none;
    }

    ${mediaHoverable} {
      &:hover {
        background-color: ${props => props.theme.palette.neutral.default.seven};

        .close-button {
          pointer-events: auto;
          opacity: 1;
        }
      }
    }
  }
`
interface Props {
  recentSearch: App.FullRecentSearch;
  onClose: (recentSearch: App.FullRecentSearch) => void;
  onClick: (recentSearch: App.FullRecentSearch) => void;
}

function RecentSearchTile(props: Props) {
  const { recentSearch, onClick, onClose } = props

  const handleDismissed = useCallback((event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    onClose(recentSearch)
  }, [onClose, recentSearch])

  const imageId = useMemo(() => {
    return recentSearch.searchItem.imageId
  }, [recentSearch.searchItem.imageId])

  const { searchTitle, dateText, occupancyText } = useMemo(() => {
    const { dates, rooms, searchItem } = recentSearch

    let searchTitle = searchItem.format.mainText
    if (searchItem.searchType === 'destination') {
      searchTitle = searchItem.format.mainText !== 'Anywhere' ?
        `Hotels in ${searchItem.format.mainText}` :
        `Hotels • ${searchItem.format.mainText}`
    } else if (searchItem.searchType === 'landmark') {
      searchTitle = `Hotels near ${searchItem.format.mainText}`
    }

    const dateText = getRecentSearchDescription(dates.checkIn, dates.checkOut, dates.flexibleMonths, dates.flexibleDuration as DEFAULT_FLEXIBLE_DURATION_RANGE, dates.isAnytime)

    const occupancyText = `${formatOccupantsAsGuests(...rooms)} • ${pluralizeToString('room', rooms.length)}`

    return { searchTitle, dateText, occupancyText }
  }, [recentSearch])

  return (
    <Root onClick={() => onClick(recentSearch)}>
      <Group direction="horizontal" verticalAlign="stretch">
        <RecentSearchImage imageId={imageId} searchItem={recentSearch.searchItem}/>
        <ContentGroup direction="horizontal" verticalAlign="space-between">
          <Group direction="horizontal" horizontalAlign="space-between" fullWidth>
            <TextContainer direction="vertical" verticalAlign="center" >
              <BodyText variant="medium" weight="bold" lineClamp={1}>{searchTitle}</BodyText>
              <BodyText variant="medium" lineClamp={1}>{dateText}</BodyText>
              <BodyText variant="medium" lineClamp={1}>{occupancyText}</BodyText>
            </TextContainer>
            <CloseButton
              onClick={handleDismissed}
              className="close-button"
            />
          </Group>
        </ContentGroup>
      </Group>
    </Root>
  )
}

export default RecentSearchTile
