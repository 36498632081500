import { SupportedDestinations } from 'home/constants'
import moment from 'moment'

export type LANDING_PAGE_ARTICLES = {
  [destination: string]: Array<App.Article>
}

const defaultArticles = [{
  imageId: '5tla9gu7yebkoib4otkl',
  title: 'Legendary Luxury: 5 Things to Know About Raffles Maldives',
  date: moment('2022-11-22'),
  description: 'One of the world’s most iconic hotel brands meets the holiday destination of dreams: welcome to Raffles Maldives.',
  tags: ['Accommodation', 'Bucket List', 'Islands'],
  slug: 'legendary-luxury-5-things-to-know-about-raffles-maldives',
}, {
  imageId: 'vutkqp5qsbew2tetwqz2',
  title: 'The Top 10 Restaurants in Bali for 2023',
  date: moment('2023-03-02'),
  description: 'From ultra-sustainable seafood in the island’s hippest beach club to smoky-sweet desserts in laidback Canggu, there’s simply no better place to eat than Bali.',
  tags: ['Accommodation', 'Food & Wine', 'Islands'],
  slug: 'the-top-10-restaurants-in-bali-for-2023',
}, {
  imageId: 'bt1d27l5ssidwo597j84',
  title: 'Luxury Escapes’ Top Bucket-List Destinations for 2023',
  date: moment('2022-12-02'),
  description: 'From Maltese citadels to Cuban rum bars, tread the corners of the world less travelled at these top bucket-list destinations for 2023, as chosen by our travel insiders.',
  tags: ['Bucket List', 'Islands', 'Outdoor & Adventure'],
  slug: 'luxury-escapes-top-bucket-list-destinations-for-2023',
}]

const australiaAndAsiaArticles = [{
  imageId: '3kt4ue81a0wetaunu5s',
  title: 'The Best All Inclusive Resorts in Thailand',
  date: moment('2022-05-11'),
  description: 'You won’t need to lift a finger when you stay at the best all-inclusive resorts Thailand has to offer.',
  tags: ['Accommodation', 'Islands'],
  slug: 'the-best-all-inclusive-resorts-in-thailand-for-2022',
},
{
  imageId: 'ojyl1j534o4kmwkyjald',
  title: 'Luxury Escapes’ Top Bucket-List Destinations for 2024',
  date: moment('2023-06-05'),
  description: 'From shrines and shinkansen in Japan to Sri Lanka safaris, these are our top bucket-list tour destinations of 2024, as chosen by our travel insiders.',
  tags: ['Accommodation', 'Arts & Culture', 'Bucket List', 'Experiences', 'Outdoor & Adventure'],
  slug: 'luxury-escapes-top-bucket-list-destinations-for-2024',
}, {
  imageId: 'drhvaz7dmsy0b8rxcbr9',
  title: 'The Best Family Resorts in Fiji that Kids Will Love',
  date: moment('2022-11-10'),
  description: 'Fiji is famed for its family-friendly approach, with top-notch kids’ clubs and nanny services. Here’s our pick of the best family resorts in Fiji.',
  tags: ['Family Friendly', 'Islands'],
  slug: 'the-best-family-resorts-in-fiji-that-kids-will-love',
}]

const northAmericaArticles = [{
  imageId: 'b9zscnyfu1jgdt0jnw',
  title: 'Luxury Escapes’ Guide to the Best All Inclusive Resorts of All Time',
  date: moment('2023-08-15'),
  description: 'Sometimes there’s nothing more tempting than a fly-and-flop with everything you could want at your fingertips. These are the luxury resorts that do it best.',
  tags: ['Accommodation', 'Family Friendly', 'Islands', 'Romantic', 'Ultra Lux'],
  slug: 'luxury-escapes-guide-to-the-best-all-inclusive-resorts-of-all-time',
},
{
  imageId: '0on515yb0rhspnl20ef',
  title: '8 Unique Experiences in the Maldives',
  date: moment('2022-12-01'),
  description: 'From romantic underwater dinners to glass-bottom canoe trips, these are the most unique and memory-making experiences in the Maldives.',
  tags: ['Bucket List', 'Islands', 'TV'],
  slug: '8-unique-experiences-in-the-maldives',
}, {
  imageId: 'ojyl1j534o4kmwkyjald',
  title: 'Luxury Escapes’ Top Bucket-List Destinations for 2024',
  date: moment('2023-06-05'),
  description: 'From shrines and shinkansen in Japan to Sri Lanka safaris, these are our top bucket-list tour destinations of 2024, as chosen by our travel insiders.',
  tags: ['Accommodation', 'Arts & Culture', 'Bucket List', 'Experiences', 'Outdoor & Adventure'],
  slug: 'luxury-escapes-top-bucket-list-destinations-for-2024',
}]

export const HOMEPAGE_ARTICLES: { [regionCode: string]: Array<App.Article> } = {
  NZ: defaultArticles,
  GB: defaultArticles,
  FR: defaultArticles,
  DE: defaultArticles,
  IE: defaultArticles,
  IT: defaultArticles,
  NL: defaultArticles,
  ES: defaultArticles,
  ZA: defaultArticles,
  QA: defaultArticles,
  SA: defaultArticles,
  AE: defaultArticles,
  IL: defaultArticles,
  AU: australiaAndAsiaArticles,
  CN: australiaAndAsiaArticles,
  HK: australiaAndAsiaArticles,
  IN: australiaAndAsiaArticles,
  ID: australiaAndAsiaArticles,
  JP: australiaAndAsiaArticles,
  KR: australiaAndAsiaArticles,
  MO: australiaAndAsiaArticles,
  MY: australiaAndAsiaArticles,
  PH: australiaAndAsiaArticles,
  SG: australiaAndAsiaArticles,
  TW: australiaAndAsiaArticles,
  TH: australiaAndAsiaArticles,
  VN: australiaAndAsiaArticles,
  US: northAmericaArticles,
  CA: northAmericaArticles,
}

export const DESTINATION_LANDING_PAGE_ARTICLES: LANDING_PAGE_ARTICLES = {
  [SupportedDestinations.BALI]: [
    {
      imageId: 'gxebjf8q6oh9toagzng3',
      title: 'Un-Bali-Vable: 7 Things to do in Bali to Make Your Escape Unforgettable',
      date: moment('2022-07-29'),
      description: 'Whether it’s a world-famous clifftop bar or an epic underwater sea walking experience, take your adventure to extraordinary heights with our top things to do in Bali.',
      tags: ['Islands', 'Outdoor & Adventure', 'TV'],
      slug: 'un-bali-vable-7-things-to-do-in-bali-to-make-your-escape-unforgettable',
    },
    {
      imageId: 'h30a7n16mbq9mna8u6f5',
      title: 'Bali’s Best Beach Hotels & Resorts',
      date: moment('2022-05-27'),
      description: 'When it comes to beachside bliss on the Island of the Gods, Bali’s best beach hotels and resorts are the cream of the crop.',
      tags: ['Accommodation'],
      slug: 'balis-best-beach-hotels-resorts',
    },
    {
      imageId: '6kl19y7l3vllda999dkf',
      title: 'Bali’s Best Rooftop Bars',
      date: moment('2022-08-16'),
      description: 'The beauty of this beloved Indonesian island is best viewed from above (with a tasty cocktail in hand) from Bali’s best rooftop bars.',
      tags: ['Experiences', 'Food & Wine'],
      slug: 'balis-best-rooftop-bars',
    },
  ],
  [SupportedDestinations.GREECE]: [
    {
      imageId: 'q5zzph68f90y2ygmr5ic',
      title: 'An Epic Guide to the Best Greek Islands',
      date: moment('2022-04-06'),
      description: 'Ancient ruins and jaw-dropping beaches: discover our guide to the best Greek Islands.',
      tags: ['Accommodation', 'Bucket List', 'Experiences', 'Family Friendly', 'Honeymoon', 'Islands'],
      slug: 'an-epic-guide-to-the-best-greek-islands',
    },
    {
      imageId: 'qnks76mc3pbr5bq6w63',
      title: 'Uncover Greece’s Best-Kept Secret: The Peloponnese Peninsula',
      date: moment('2023-06-09'),
      description: 'Go to where olives thrive, rugged coastlines reign supreme and Mediterranean culture abounds – the Peloponnese Peninsula, a hidden gem on Greece’s southern coastline.',
      tags: ['Islands', 'Outdoor & Adventure', 'Sun & Beach'],
      slug: 'uncover-greeces-best-kept-secret-the-peloponnese-peninsula',
    },
    {
      imageId: '1luzgg7nam9f3s3m5ivi',
      title: 'Why Crete is a Must on Your Greek Island Itinerary',
      date: moment('2023-02-13'),
      description: 'From turquoise tides and pink-sand beaches to ancient ruins, windmills and hillside villages – the magic of Crete is waiting to be discovered.',
      tags: ['Accommodation', 'Experiences', 'Islands'],
      slug: 'why-crete-is-a-must-on-your-greek-island-itinerary',
    },
  ],
  [SupportedDestinations.MALDIVES]: [
    {
      imageId: 'dfll9dd2reliu7pgd6c5',
      title: 'The Best Luxury Spa Experiences in the Maldives',
      date: moment('2023-05-22'),
      description: 'With spas in secluded locations surrounded by crystal-clear waters and lush, tropical jungle, these are the best luxury spa experiences in the Maldives.',
      tags: ['Accommodation', 'Honeymoon', 'Islands', 'Romantic', 'Spa'],
      slug: 'the-best-luxury-spa-experiences-in-the-maldives',
    },
    {
      imageId: 'q29z5utjsvcd1pchl20tc',
      title: 'A Guide to the Atolls of the Maldives',
      date: moment('2022-05-18'),
      description: 'Discover our guide to the atolls of the Maldives: the perfect tool to help you choose your perfect Maldives escape.',
      tags: ['Experiences', 'Honeymoon', 'Islands', 'Outdoor & Adventure'],
      slug: 'a-guide-to-the-atolls-of-the-maldives',
    },
    {
      imageId: 'pda3ddw3cqgxd4efgxe',
      title: 'Which Maldives Resort is Best for You?',
      date: moment('2022-09-02'),
      description: 'From havens of wellness to romantic honeymoon escapes and family-friendly resorts, the Maldives has more diversity than you might expect.',
      tags: ['Accommodation', 'Bucket List', 'Honeymoon', 'Islands'],
      slug: 'which-maldives-resort-is-best-for-you',
    },
  ],
  [SupportedDestinations.SPAIN]: [
    {
      imageId: '2u3g295021dj01dgaqi67',
      title: 'Hola España: How to Choose Your Spain Escape ',
      date: moment('2023-05-29'),
      description: 'Tantalising tempranillo vineyards, flamingo-filled islands and city streets that swagger with Flamenco soul – here’s our guide to finding your next Spain escape.',
      tags: ['Destinations', 'Europe'],
      slug: 'how-to-choose-your-spain-escape',
    },
    {
      imageId: '7d8zp5ocghqmhmiqquj',
      title: 'Spain’s Most Beautiful City Breaks ',
      date: moment('2022-05-10'),
      description: 'Discover unexplored corners and hidden gems with our ultimate guide to Spain’s best city breaks.',
      tags: ['Destinations', 'Europe', 'Spain', 'What to see and do'],
      slug: 'spains-most-beautiful-city-breaks',
    },
    {
      imageId: '2kj4ybtjqh4d9q0qzqb',
      title: 'Ultimate Barcelona Guide: 8 Unmissable Things to Do',
      date: moment('2023-07-18'),
      description: '300 annual days of sunshine, Gaudí marvels around every corner and top-notch tapas just scratches the surface of what the Catalonian capital has to offer.',
      tags: ['Destinations', 'Europe', 'Explore', 'Spain'],
      slug: 'ultimate-barcelona-guide-8-unmissable-things-to-do',
    },
  ],
}
