import React from 'react'
import { MAX_CAROUSEL_ITEM_COUNT } from './common/constants'
import { LOOKING_FOR_SOMETHING_ELSE_CONTENT } from '../LookingForSomethingElse/constants'
import { isManualSortingEnabledFor } from 'selectors/featuresSelectors'
import OfferCarouselLookingForSomethingElse from '../LookingForSomethingElse/OfferCarouselLookingForSomethingElse'
import OfferCardCarouselSection from 'components/Common/OfferCardCarousel/OfferCardCarouselSection'

const OFFER_TYPES: Array<App.OfferListOfferType> = ['hotel', 'tactical_ao_hotel', 'last_minute_hotel', 'tour', 'tour_v2']
const DEFAULT_PUBLIC_OFFER_PARAMS: App.OfferListFilters = {
  offerTypes: OFFER_TYPES,
  frontPageVisibleOnly: true,
  limit: MAX_CAROUSEL_ITEM_COUNT,
  propertySubCategories: ['Ultra Lux'],
  sortBy: 'homepage',
}
const DEFAULT_UNIFIED_ENDPOINT_PARAMS: App.OfferListFilters = {
  offerTypes: OFFER_TYPES,
  limit: MAX_CAROUSEL_ITEM_COUNT,
  holidayTypesScoped: ['Ultra Lux'],
  sortBy: 'vertical-ultralux',
}
interface Props {
  title?: React.ReactNode;
}

function UltraLuxOfferCarousel(props: Props) {
  const { title } = props
  const isManualSortingEnabled = isManualSortingEnabledFor('ultralux')
  const filters = isManualSortingEnabled ? DEFAULT_UNIFIED_ENDPOINT_PARAMS : DEFAULT_PUBLIC_OFFER_PARAMS

  return (
    <OfferCardCarouselSection
      filters={filters}
      title={title ?? <>Indulge in <i>ultra lux</i> escapes</>}
      allLink="/ultra-lux"
      additionalFilters="holidayTypes"
      tileStyle="card-medium"
    >
      <OfferCarouselLookingForSomethingElse to={LOOKING_FOR_SOMETHING_ELSE_CONTENT.ultralux.to} />
    </OfferCardCarouselSection>
  )
}

export default React.memo(UltraLuxOfferCarousel)
